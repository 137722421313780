<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1001096')" prop="types">
              <Select v-model="pageParams.types" filterable multiple>
                <Option v-for="(item, index) in importTypeList" :key="index" :value='item.value'>{{ item.name }}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1000271')" prop="operateCode">
              <Input :placeholder="$t('key1001097')" clearable v-model.trim="pageParams.operateCode"></Input>
            </Form-item>
          </Col>
          <Col :xxl="threeItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1001098')" class="date_picker_style">
              <Date-picker
                type="datetimerange"
                @on-clear="resetDate"
                @on-change="getDateValue"
                :clearable="true"
                transfer
                :options="dateOptions"
                format="yyyy-MM-dd HH:mm:ss"
                placement="bottom-end"
                :placeholder="$t('key1000273')"
                :value="registerTime"></Date-picker>
            </Form-item>
          </Col>
          <Col style="width: 200px;" push="1">
            <Button @click="reset" icon="md-refresh" class="mr12">{{ $t('key1000095') }}</Button>
            <Button type="primary" @click="search" icon="md-search">{{ $t('key1000274') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box">
      <local-tabButton :tabButtonList="importStatusList" @selectStatus="importStatusBtn"></local-tabButton>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table
        border
        highlight-row
        max-height="550"
        :loading="tableLoading"
        :columns="importColumn"
        :data="importData">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        :current="pageParams.pageNum"
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
  </div>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import {downloadTemplateFile} from "@/utils/common";

export default {
  name: "publicImport",
  mixins: [Mixin],
  props: {
    importTypeList: { // 导入类型
      type: Array,
      default: () => []
    }
  },
  data() {
    let v = this;
    return {
      pageParamsStatus: false, // 是否重新加载列表数据
      total: 0, // 总记录数
      pageParams: {
        types: [],
        status: null,
        operateCode: null,
        createdTimeStart: null,
        createdTimeEnd: null,
        pageSize: 10,
        pageNum: 1
      },
      registerTime: [],
      importStatusList: [ // 2:操作中 3:操作完成 4：操作失败(格式校验不通过或其他运行时异常)
        {
          selected: true,
          value: null,
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000096')
        },
        {
          selected: false,
          value: 2,
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001099'),
          type: 'warning'
        },
        {
          selected: false,
          value: 3,
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001100'),
          type: 'success'
        },
        {
          selected: false,
          value: 4,
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001101'),
          type: 'error'
        }
      ],
      importData: [],
      importColumn: [
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000275'),
          width: 70,
          align: 'center',
          type: 'index',
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000276'),
          key: 'operateCode',
          align: 'center',
          minWidth: 120
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001102'),
          key: 'types',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            let type = params.row.type;
            let text = '';
            v.importTypeList.map((item) => {
              if (item.value === type) {
                text = item.name;
              }
            });
            return h('span', text);
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001103'),
          key: 'originPath',
          align: 'center',
          minWidth: 210,
          render: (h, params) => {
            return h('div', {
              style: {
                color: params.row.originPath ? '#0000FF' : '#5a516e',
                cursor: params.row.originPath ? 'pointer' : 'initial'
              },
              on: {
                click: () => {
                  if (params.row.originPath) {
                    downloadTemplateFile(params.row.originPath);
                  }
                }
              }
            }, params.row.originName);
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001104'),
          key: 'createdTime',
          align: 'center',
          minWidth: 120,
          sortable: true,
          render: (h, params) => {
            return h('div', v.$uDate.dealTime(params.row.createdTime, 'fulltime'));
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001105'),
          key: 'status',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            let status = params.row.status;
            return h('Badge', {
              props: {
                status: v.importStatusList.filter((item) => {
                  return item.value === status
                })[0].type,
                text: v.importStatusList.filter((item) => {
                  return item.value === status
                })[0].title || '--'
              }
            });
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000222'),
          key: 'createdBy',
          align: 'center',
          minWidth: 120,
          render: (h, params) => {
            let userName = '';
            if (v.userInfoList !== null && v.userInfoList[params.row.updatedBy] !== undefined) {
              userName = v.userInfoList[params.row.updatedBy].userName;
            } else if (params.row.createdBy === alias47916751af154eb5b47cd5de9d34633d.t('key1000281')) {
              userName = alias47916751af154eb5b47cd5de9d34633d.t('key1000281');
            } else {
              userName = '';
            }
            return h('div', userName);
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1001106'),
          key: 'successFailure',
          align: 'center',
          minWidth: 120,
          renderHeader: (h, params) => {
            return h('div', [
              h('span', {
                style: {
                  color: '#008000'
                }
              }, alias47916751af154eb5b47cd5de9d34633d.t('key1001107')),
              h('span', ' / '),
              h('span', {
                style: {
                  color: '#FF0000'
                }
              }, alias47916751af154eb5b47cd5de9d34633d.t('key1001108'))
            ]);
          },
          render: (h, params) => {
            if (params.row.status !== 2 && params.row.status !== 4) {
              return h('div', [
                h('span', {
                  style: {
                    color: '#008000'
                  }
                }, params.row.success),
                h('span', ' / '),
                h('span', {
                  style: {
                    color: '#FF0000'
                  }
                }, params.row.failure)
              ]);
            }
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000283'),
          width: 200,
          fixed: 'right',
          align: 'center',
          renderHeader(h, params) {
            return h('div', {class: 'flex align-items-center'}, [
              h('span', alias47916751af154eb5b47cd5de9d34633d.t('key1000283')),
              h('Tooltip', {
                props: {
                  offset: 13,
                  transfer: true,
                  maxWidth: 150,
                  content: alias47916751af154eb5b47cd5de9d34633d.t('key1000284'),
                  placement: 'top-end'
                }
              }, [
                h('Icon', {
                  props: {
                    type: 'ios-help-circle-outline',
                    size: 16
                  },
                  style: {
                    cursor: 'pointer',
                    marginLeft: '5px'
                  }
                })
              ])
            ]);
          },
          render: (h, params) => {
            return h('optsButtonList', {
              props: {
                operationList: [
                  {
                    text: alias47916751af154eb5b47cd5de9d34633d.t('key1000285'),
                    show: params.row.status !== 2 && params.row.status !== 4 && params.row.failure !== 0 && params.row.targetPath,
                    clickFn: () => {
                      downloadTemplateFile(params.row.targetPath);
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ]
    };
  },
  created() {
    if (this.$route.query && this.$route.query.operateCode) {
      this.pageParams.operateCode = this.$route.query.operateCode;
    }
    this.getUserName();
    this.search();
  },
  methods: {
    // 选取导入状态
    importStatusBtn(index, value) {
      let v = this;
      v.pageParams.status = value;
      v.importStatusList.forEach((n, i) => {
        n.selected = i === index;
      });
      v.pageParamsStatus = true;
    },
    // 重置创建时间
    resetDate() {
      this.pageParams.createdTimeStart = null;
      this.pageParams.createdTimeEnd = null;
    },
    // 获取日期返回值
    getDateValue(value) {
      let v = this;
      if (value.length === 0) {
        v.$Message.error(alias47916751af154eb5b47cd5de9d34633d.t('key1000289'));
        v.resetDate();
      } else {
        if (!value[0]) {
          v.pageParams.createdTimeStart = null;
          v.pageParams.createdTimeEnd = null;
        } else {
          let timeList = v.defaultTimePeriod(value);
          v.registerTime = v.defaultTimePeriod(value, false);
          v.pageParams.createdTimeStart = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
          v.pageParams.createdTimeEnd = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
        }
      }
    },
    // 查询
    search() {
      let v = this;
      v.pageParams.pageNum = 1;
      v.pageParamsStatus = true;
    },
    // 重置数据
    reset() {
      let v = this;
      v.$refs['pageParams'].resetFields();
      v.importStatusBtn(0, null);
      v.resetDate();
      v.pageParams.types = [];
      v.registerTime = [];
      v.search();
    },
    // 获取列表数据
    getList() {
      let v = this;
      v.importData = [];
      v.total = 0;
      if (v.getPermission('erpCommon_queryTask')) {
        let types = [];
        if (!v.pageParams.types.length) {
          v.importTypeList.map((item) => {
            types.push(item.value);
          });
        } else {
          types = v.pageParams.types;
        }
        let obj = Object.assign({}, v.pageParams);
        obj.types = types;
        v.tableLoading = true;
        v.axios.post(api.post_erpCommon_queryTask, obj).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            v.importData = data.list || [];
            v.total = data.total;
            v.$nextTick(() => {
              v.tableLoading = false;
            });
          } else {
            v.tableLoading = false;
          }
        });
      } else {
        v.gotoError();
      }
    }
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.getList();
        v.pageParamsStatus = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
