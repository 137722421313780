<template>
  <publicImport :importTypeList="importTypeList"></publicImport>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import publicImport from "@/components/common/publicImport";

export default {
  name: "importTask",
  data() {
    return {
      importTypeList: [
        {value: 'productImport', name: alias47916751af154eb5b47cd5de9d34633d.t('key1000725')},
        {value: 'inventoryImport', name: alias47916751af154eb5b47cd5de9d34633d.t('key1000759')},
        {value: 'productGoodsSuggestPriceImport', name: alias47916751af154eb5b47cd5de9d34633d.t('key1000791')},
        {value: 'productGoodsStatusImport', name: alias47916751af154eb5b47cd5de9d34633d.t('key1005854')},
        {value: 'importSupplierStockOrder', name: alias47916751af154eb5b47cd5de9d34633d.t('key1004628')},
      ]
    };
  },
  components: {
    publicImport
  }
};
</script>
<style scoped>

</style>
